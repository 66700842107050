<template>
  <base-dialog-form
    :dialog="dialog"
    :loading="loading"
    :title="title"
    action-text="Save jurisdiction details"
    @close="$emit('close')"
    @submit="submit()"
  >
    <template #content>
      <v-text-field
        dense
        outlined
        persistent-hint
        label="Name *"
        class="rounded-lg"
        v-model="jurisdictionObj.name"
        :hint="errors.get('name')"
        :error="errors.has('name')"
        @input="errors.clear('name')"
      ></v-text-field>

      <v-select
        dense
        outlined
        return-object
        persistent-hint
        item-text="name"
        label="Boundary *"
        class="rounded-lg"
        v-model="boundary"
        :items="boundaries.data"
        :hint="errors.get('boundary_uid')"
        :error="errors.has('boundary_uid')"
        @input="errors.clear('boundary_uid')"
      >
        <template v-slot:item="{item}">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action class="caption">
              {{ item.country.name }}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-select>

      <v-autocomplete
        dense
        outlined
        hide-selected
        persistent-hint
        class="rounded-lg"
        label="Parent jurisdiction"
        item-text="name"
        item-value="jurisdiction_uid"
        :items="jurisdictions.data"
        :search-input.sync="searchJurisdiction"
        v-model="jurisdictionObj.parent_uid"
        :hint="errors.get('parent_uid')"
        :error="errors.has('parent_uid')"
        @input="errors.clear('parent_uid')"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.boundary.name }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text>
              {{ item.boundary.level }}
            </v-list-item-action-text>
          </v-list-item-action>
        </template>
      </v-autocomplete>
    </template>
  </base-dialog-form>
</template>

<script>
import Jurisdiction from '@/libs/nchi/Jurisdiction'
import { mapActions, mapGetters } from 'vuex'
import * as _ from 'lodash'

export default {
  props: {
    dialog: {
      required: true,
      type: Boolean,
    },

    jurisdiction: {
      type: Object
    }
  },

  data () {
    return {
      loading: false,
      boundary: null,
      searchJurisdiction: '',
      jurisdictionObj: new Jurisdiction(),
    }
  },

  watch: {
    boundary (boundary) {
      this.jurisdictionObj.parent_uid = null
      this.jurisdictionObj.boundary_uid = boundary.boundary_uid
      if (boundary.level !== 1) {
        this.loadJurisdictions()
      }
    },

    searchJurisdiction (n, o) {
      if (n && n.length > 2) {
        this.searchJurisdictions(n)
      }

      if (!n && o) {
        this.loadJurisdictions()
      }
    }
  },

  computed: {
    ...mapGetters({
      boundaries: 'getBoundaries',
      jurisdictions: 'getJurisdictions',
    }),

    title () {
      const action = this.jurisdiction ? 'Edit' : 'Create'
      return `${action} Jurisdiction`
    },

    errors () {
      return this.jurisdictionObj.form.errors
    },
  },

  methods: {
    ...mapActions([
      'setBoundaries',
      'setJurisdictions',
    ]),

    mapJurisdiction () {
      if (this.jurisdiction) {
        Object.keys(this.jurisdiction).forEach((key) => {
          if (this.jurisdictionObj.hasOwnProperty(key)) {
            this.jurisdictionObj[key] = this.jurisdiction[key]
          }
        })
      }
    },

    submit () {
      if (!this.loading) {
        this.loading = true
        !this.jurisdiction ? this.store() : this.update()
      }
    },

    store () {
      this.jurisdictionObj.store().then(() => {
        this.setJurisdictions().then(() => {
            this.$emit('close')
          })
      }).finally(() => {
        this.loading = false
      })
    },

    searchJurisdictions: _.debounce(function (search) {
      this.loadJurisdictions(search)
    }),

    loadJurisdictions (search = '') {
      this.setJurisdictions({
        params: {
          ...(this.boundary && {
            country_uid: this.boundary.country_uid,
          }),
          ...(this.boundary.level !== 1 && {
            level: this.boundary.level - 1
          }),
          ...(search && { search })
        }
      })
    }
  },

  mounted () {
    this.mapJurisdiction()
    this.setBoundaries()
  }
}
</script>