import Base from '@/libs/core/Base'

const fields = {
  name: '',
  parent_uid: '',
  boundary_uid: '',
  jurisdiction_uid: '',  
}

export default class Jurisdiction extends Base {
  constructor () {
    super(fields)
  }

  store () {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await this.form.submit('post', 'nchi/jurisdictions', this.getFields())
        this.setFields(fields)
        resolve(response)
      } catch (err) {
        reject(err)
      }
    })
  }
}